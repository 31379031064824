import { useState } from "react";

const CustomerChat = () => {
  const [typedMessage, setTypedMessage] = useState("");
  const [selectedChat, setSelectedChat] = useState({
    name: "",
    message: "",
    date: "",
    time: "",
  });

  const chatData = [
    {
      name: "Angela",
      message: "wejkrjkhrjeh wieuhwuhu kfkfrkht",
      date: "20/02/2034",
      time: "12:23pm",
    },
    {
      name: "Musa",
      message: "dsldlsgufnrg kldlfk;ek alsd;oaef",
      date: "27/02/2034",
      time: "12:23pm",
    },
    {
      name: "Daniel",
      message: "sdf;;t kjwe jefhe akdflsj",
      date: "20/02/2034",
      time: "12:23pm",
    },
    {
      name: "James",
      message: "fshdshflr lsiroukhefsdfg adgrt",
      date: "20/02/2034",
      time: "12:23pm",
    },
    {
      name: "Grace",
      message: "sdfghj yuiop tgv lkj",
      date: "20/02/2034",
      time: "12:23pm",
    },
    {
      name: "John",
      message: "sdf;gr uifru kfkfrkht",
      date: "20/02/2034",
      time: "12:23pm",
    },
    {
      name: "Brown",
      message: "djdjgj eyr sh t",
      date: "20/02/2034",
      time: "12:23pm",
    },
    {
      name: "Sam",
      message: "wdfk fhsdkh sdkfjf ald rt",
      date: "20/02/2034",
      time: "12:23pm",
    },
  ];

  return (
    <div>
      <h1 className="text-lg font-bold">Customer Chats</h1>
      <section className="flex gap-3 bg-white p-5 my-3 rounded-md">
        <div className="w-1/3 border rounded-md">
          <div className="flex justify-between items-center px-2 py-4 shadow-md">
            <h3 className="font-semibold text-lg">All Chats</h3>
            <span className="bg-secondary text-white p-1 rounded-full w-7 text-center text-xs">
              {chatData.length}
            </span>
          </div>
          <div className="md:h-[26rem] overflow-auto">
            {chatData && chatData.length > 0 ? (
              chatData.map((data, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedChat(data)}
                  className="border-y p-2 py-5 my-2 flex justify-between hover:bg-gray-100 cursor-pointer"
                >
                  <p>{data.name}</p>
                  <p>{data.date}</p>
                </div>
              ))
            ) : (
              <p>No available chat</p>
            )}
          </div>
        </div>
        <section className="border w-2/3 p-2 relative">
          {Object.values(selectedChat).every((value) => value !== "") ? (
            <>
              <div className="flex justify-between border-b py-3">
                <h2>{selectedChat.name}</h2>
                <p>{selectedChat.date}</p>
              </div>

              <div>
                <div className="border rounded-md p-2 bg-secondary text-white w-1/3 my-2 text-sm">
                  <p>{selectedChat.message}</p>
                  <p className="text-xs text-right">{selectedChat.time}</p>
                </div>
              </div>

              <div className="absolute flex justify-between items-center w-full bottom-0 pr-3">
                <textarea
                  name="text"
                  id="text"
                  placeholder="Write a message"
                  className="text-sm p-2 border border-b-0 w-11/12 resize-none"
                  rows={4}
                  value={typedMessage}
                  onChange={(e) => setTypedMessage(e.target.value)}
                ></textarea>
                <button
                  type="button"
                  className="text-sm bg-secondary p-2 text-white rounded-md"
                >
                  Send
                </button>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-5xl opacity-10 font-bold">Chats</p>
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

export default CustomerChat;
