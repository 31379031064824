import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

const UserDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllIncompleteUsers = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${API_URL}/incompleteRegisteration`, {
        headers: {
          Authorization: token,
        },
      });
      setUsers(res.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllIncompleteUsers();
  }, []);

  return (
    <div>
      <h1 className="text-lg font-semibold ">User Details</h1>
      <section className="bg-white p-5 rounded-md mt-5">
        <table className="w-full text-left">
          <thead>
            <tr className="bg-gray-50 font-bold h-12 md:text-base text-sm">
              <th>SN</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody className="px-4">
            {isLoading ? (
              <LoadingSpinner />
            ) : users ? (
              users.map((user: any, index) => (
                <tr key={index} className="border-b border-gray-300 py-2">
                  <td className="text-secondary p-2">{index + 1}</td>
                  <td className="p-2">
                    {user.firstName && user.lastName
                      ? user.firstName + " " + user.lastName
                      : "No name"}
                  </td>
                  <td className="p-2">
                    {user.email ? user.email : "No email"}
                  </td>
                  <td className="p-2">
                    {user.phoneNo ? user.phoneNo : "No number"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-secondary text-center">No User found</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default UserDetails;
