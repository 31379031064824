import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { API_URL } from "../App";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

export const Assignment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("All");
  const [admins, setAdmins] = useState([]);
  const { token } = useAppSelector((store: RootState) => store.auth);

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllAdmins = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${API_URL}/get-admins-except-superadmin`
      );
      setIsLoading(false);
      setAdmins(res.data);
    } catch (error) {
      console.log(error);
      setAdmins([]);
      setIsLoading(false);
    }
  };

  const addRoleHandler = async ({
    id,
    roleValue,
  }: {
    id: string;
    roleValue: string;
  }) => {
    const payload = {
      staffId: id,
      role: roleValue,
    };
    try {
      const res = await axios.post(
        `${API_URL}/addAdminRole`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getAllAdmins();
      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error("Unable to add admin role!");
    }
  };

  const removeRoleHandler = async (adminID: string, roleId: string) => {
    console.log(
      `${API_URL}/delete-admin-role/${adminID}/${roleId}`
    );
    try {
      const res = await axios.delete(
        `${API_URL}/delete-admin-role/${adminID}/${roleId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message);
      getAllAdmins();
    } catch (error) {
      console.error(error);
      toast.error("Unable to remove admin role!");
    }
  };

  return (
    <main className="w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`overflow-x-auto ${isLoading && "animate-pulse h-[50vh]"}`}
        >
          <div className="flex items-center justify-between p-6">
            <h1 className="text-base font-semibold">Assignment</h1>
            <div className="flex items-center">
              <label htmlFor="filterAdmin">
                Sort by:
                <select
                  name="filterAdmin"
                  id="filterAdmin"
                  className="border p-2 ml-4"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="ssa">SSA</option>
                  <option value="admin">Admin</option>
                </select>
              </label>
            </div>
          </div>
          <section className="w-full p-5 overflow-x-auto">
            <table
              className={`p-5 text-center w-[1200px] ${
                isLoading && "animate-pulse h-[60vh]"
              }`}
            >
              <thead className="bg-gray-50 font-bold h-12">
                <tr className="p-5">
                  <th className="text-sm text-nowrap px-2">S/N</th>
                  <th className="text-sm text-nowrap px-2">Name</th>
                  <th className="text-sm text-nowrap px-2">News Update</th>
                  <th className="text-sm text-nowrap px-2">Store</th>
                  <th className="text-sm text-nowrap px-2">Logistics</th>
                  <th className="text-sm text-nowrap px-2">Restaurants</th>
                  <th className="text-sm text-nowrap px-2">Dietitians</th>
                  <th className="text-sm text-nowrap px-2">Vendors</th>
                  <th className="text-sm text-nowrap px-2">Staff</th>
                  <th className="text-sm text-nowrap px-2">Operations</th>
                  <th className="text-sm text-nowrap px-2">Virtual Account</th>
                  <th className="text-sm text-nowrap px-2">E-Verification 1</th>
                  <th className="text-sm text-nowrap px-2">
                    SE-Verification 1
                  </th>
                  <th className="text-sm text-nowrap px-2">S-Verification 1</th>
                  <th className="text-sm text-nowrap px-2">Document Check</th>
                  <th className="text-sm text-nowrap px-2">Institute Upload</th>
                  <th className="text-sm text-nowrap px-2">Next Pay</th>
                  <th className="text-sm text-nowrap px-2">E-mandate</th>
                  <th className="text-sm text-nowrap px-2">Users</th>
                  <th className="text-sm text-nowrap px-2">Orders</th>
                  <th className="text-sm text-nowrap px-2">Defaulters</th>
                  <th className="text-sm text-nowrap px-2">Create Admin</th>
                  <th className="text-sm text-nowrap px-2">Customer Care</th>
                  <th className="text-sm text-nowrap px-2">FAQ</th>
                  <th className="text-sm text-nowrap px-2">Repayment History</th>
                </tr>
              </thead>
              <tbody className="px-4">
                {admins && admins.length > 0 ? (
                  admins
                    .filter((admin: any) => {
                      if (filter === "all") {
                        return admin;
                      } else if (filter === "ssa") {
                        return admin.adminLevel === "semi-superadmin";
                      } else if (filter === "admin") {
                        return admin.adminLevel === "admin";
                      }
                      return true;
                    })
                    .map((filteredAdmin: any, index) => {
                      const handleCheck = (value: string) => {
                        return filteredAdmin.adminRole.some(
                          (role: any) => role.role === value
                        );
                      };

                      return (
                        <tr
                          className="border-b border-gray-300 py-3"
                          key={index}
                        >
                          <td className="text-secondary p-2 font-semibold">
                            {index + 1}
                          </td>
                          <td className="p-2 text-nowrap">
                            {filteredAdmin.lastName} {filteredAdmin.firstName}
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("News update")}
                              onChange={() => {
                                if (handleCheck("News update")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "News update"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "News update",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Store")}
                              onChange={() => {
                                if (handleCheck("Store")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Store"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Store",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Logistics")}
                              onChange={() => {
                                if (handleCheck("Logistics")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Logistics"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Logistics",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Restaurants")}
                              onChange={() => {
                                if (handleCheck("Restaurants")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Restaurants"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Restaurants",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Dietitians")}
                              onChange={() => {
                                if (handleCheck("Dietitians")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Dietitians"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Dietitians",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Vendors")}
                              onChange={() => {
                                if (handleCheck("Vendors")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Vendors"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Vendors",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Staff")}
                              onChange={() => {
                                if (handleCheck("Staff")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Staff"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Staff",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Operations")}
                              onChange={() => {
                                if (handleCheck("Operations")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Operations"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Operations",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Virtual Account")}
                              onChange={() => {
                                if (handleCheck("Virtual Account")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Virtual Account"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Virtual Account",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("E-Verification 1")}
                              onChange={() => {
                                if (handleCheck("E-Verification 1")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "E-Verification 1"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "E-Verification 1",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("SE-Verification 1")}
                              onChange={() => {
                                if (handleCheck("SE-Verification 1")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "SE-Verification 1"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "SE-Verification 1",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("S-Verification 1")}
                              onChange={() => {
                                if (handleCheck("S-Verification 1")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "S-Verification 1"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "S-Verification 1",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Document Check")}
                              onChange={() => {
                                if (handleCheck("Document Check")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Document Check"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Document Check",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Institute Upload")}
                              onChange={() => {
                                if (handleCheck("Institute Upload")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Institute Upload"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Institute Upload",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Next Pay")}
                              onChange={() => {
                                if (handleCheck("Next Pay")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Next Pay"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Next Pay",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("E-mandate")}
                              onChange={() => {
                                if (handleCheck("E-mandate")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "E-mandate"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "E-mandate",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Users")}
                              onChange={() => {
                                if (handleCheck("Users")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Users"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Users",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Orders")}
                              onChange={() => {
                                if (handleCheck("Orders")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Orders"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Orders",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Defaulters")}
                              onChange={() => {
                                if (handleCheck("Defaulters")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Defaulters"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Defaulters",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Create Admin")}
                              onChange={() => {
                                if (handleCheck("Create Admin")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Create Admin"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Create Admin",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Customer Care")}
                              onChange={() => {
                                if (handleCheck("Customer Care")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Customer Care"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Customer Care",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("FAQ")}
                              onChange={() => {
                                if (handleCheck("FAQ")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "FAQ"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "FAQ",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Repayment History")}
                              onChange={() => {
                                if (handleCheck("Repayment History")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Repayment History"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Repayment History",
                                  });
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-secondary text-center pt-2 text-sm">No Admins Found!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </main>
  );
};
