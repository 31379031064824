import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../App";
import toast from "react-hot-toast";

export const login = createAsyncThunk(
  "login",
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_URL}/admin-login`,
        credentials
      );
      sessionStorage.setItem("adminLevel", response.data.user.adminLevel);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "getAllProducts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/get-products`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getProduct = createAsyncThunk(
  "getProduct",
  async (productId: any, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/get-product/${productId}`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getAllProductType = createAsyncThunk(
  "getAllProductType",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/getAllProductType`
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const updateSingleProduct = createAsyncThunk(
  "updateProduct",
  async ({ data, id }: { data: FormData; id: string }, thunkAPI) => {
    const auth: any = sessionStorage.getItem("authState");
    const jsonData = JSON.parse(auth);
    const token = jsonData.token;

    try {
      const response = await axios.put(
        `${API_URL}/update-product/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteProductType = createAsyncThunk(
  "deleteProductType",
  async (categoryId: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.delete(
        `${API_URL}/deleteProductType/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (productId: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.delete(
        `${API_URL}/delete-product/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createProductItem = createAsyncThunk(
  "createProductItem",
  async (data: any, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.post(
        `${API_URL}/create-product`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
export const createProductType = createAsyncThunk(
  "createProductType",
  async (data: any, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.post(
        `${API_URL}/createProductType`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verificationStageOne = createAsyncThunk(
  "verificationStageOne",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/verification-stage-one`
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOrders = createAsyncThunk("getOrders", async (_, thunkAPI) => {
  try {
    const auth: any = sessionStorage.getItem("authState");
    const jsonData = JSON.parse(auth);
    const token = jsonData.token;

    const response = await axios.get(
      `${API_URL}/getAllOrdersByCustomer`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getSingleCustomer = createAsyncThunk(
  "getSingleCustomer",
  async (id: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${API_URL}/getSingleCustomer/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllDirectDebitMandate = createAsyncThunk(
  "getAllDirectDebitMandate",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${API_URL}/getAllDirectDebitMandate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const allCustomerNextPay = createAsyncThunk(
  "allCustomerNextPay",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${API_URL}/allCustomerNextPay`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDefaulters = createAsyncThunk(
  "getDefaulters",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${API_URL}/defaulters`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const approveOrder = createAsyncThunk(
  "approveOrder",
  async (data: any, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.post(
        `${API_URL}/approve-order`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Order has been approved!");

      return response.data;
    } catch (error: any) {
      if (error.response.status === 502 || error.response.status === 503) {
        toast.error('Network error')
      }
      toast.error('Something went wrong')
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllBanner = createAsyncThunk(
  "getAllBanner",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${API_URL}/getAllBanner`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "deleteBanner",
  async (id: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.delete(
        `${API_URL}/deleteBanner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllDispatch = createAsyncThunk(
  "getAllDispatch",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${API_URL}/getAllDispatcherDetails`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logisticDashboard = createAsyncThunk(
  "logisticDashboard",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${API_URL}/logisticDashboardStatistic`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logisticAssignDetails = createAsyncThunk(
  "logisticAssignDetails",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${API_URL}/getDetailsForLogisticAssignDelivery`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllVendors = createAsyncThunk(
  "getAllVendors",
  async (_, thunkAPI) => {
    const auth: any = sessionStorage.getItem("authState");
    const jsonData = JSON.parse(auth);
    const token = jsonData.token;

    try {
      const res = await axios.get(
        `${API_URL}/vendors`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getFees = createAsyncThunk(
  "getFees",
  async (_, thunkAPI) => {
    const auth: any = sessionStorage.getItem("authState");
    const jsonData = JSON.parse(auth);
    const token = jsonData.token;

    try {
      const res = await axios.get(
        `${API_URL}/get-allFees`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const checkTokenExpiration = () => {
  const auth: any = sessionStorage.getItem("authState");
  const jsonData = JSON.parse(auth);
  const token = jsonData?.token;

  const tokenTimestamp = sessionStorage.getItem("tokenTimestamp");
  if (auth && token && tokenTimestamp) {
    const currentTime = new Date().getTime();
    const expirationTime =
    new Date(parseInt(tokenTimestamp, 10)).getTime() + 24 * 60 * 60 * 1000;

    if (currentTime >= expirationTime) {
      sessionStorage.clear();
      sessionStorage.removeItem("tokenTimestamp");
      return false;
    }
    return true;
  }
  return false;
};
