import { useEffect, useState } from "react";
import { Preloader } from "./elements/Preloader";

const IncompleteAccounts = ({
  incomplete,
  isLoading,
  handleDeleteClick,
}: any) => {
  const [filter, setFilter] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isActive, setIsActive] = useState("day");

  useEffect(() => {
    const filterDataByDate = () => {
      setFilteredData(
        incomplete.filter((data: any) => {
          const acctDate = new Date(data.createdAt).toLocaleDateString();
          const selectedDate = new Date(filterByDate).toLocaleDateString();
          return acctDate === selectedDate;
        })
      );
    };
    filterDataByDate();
  }, [filterByDate, incomplete]);

  useEffect(() => {
    const filterData = () => {
      setFilteredData(
        incomplete.filter((data: any) => {
          const currentDate = new Date();
          const apiDate = new Date(data.createdAt);
          const timeDifference = currentDate.getTime() - apiDate.getTime();
          const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

          if (filter === "yesterday") {
            return daysDifference === 1;
          } else if (filter === "aWeek") {
            return daysDifference >= 2 && daysDifference <= 7;
          } else if (filter === "older") {
            return daysDifference > 7;
          } else {
            return true;
          }
        })
      );
    };
    filterData();
  }, [filter, incomplete]);

  return (
    <div className="bg-white rounded-md shadow-md pb-6">
      <div className="flex justify-between w-full p-3">
        <h1 className="text-base font-semibold p-3">Incomplete Accounts</h1>
        <div>
          <label htmlFor="filterByDate">
            Filter by date:
            <input
              type="date"
              name="filterByDate"
              id="filterByDate"
              className={`border p-2 mx-4 ${
                isActive === "date" && "bg-gray-200"
              }`}
              onClick={() => setIsActive("date")}
              onChange={(e) => setFilterByDate(e.target.value)}
            />
          </label>
          <label htmlFor="filter">
            Sort:
            <select
              name="filter"
              id="filter"
              className={`border p-2 mx-4 ${
                isActive === "day" && "bg-gray-200"
              }`}
              onClick={() => setIsActive("day")}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="yesterday">Yesterday</option>
              <option value="aWeek">A week ago</option>
              <option value="older">Older</option>
            </select>
          </label>
        </div>
      </div>

      <section className="overflow-x-auto">
        <table className="w-[1200px]" style={{ minWidth: "700px" }}>
          <thead className="bg-gray-50 font-bold p-4 text-left text-nowrap">
            <tr>
              <th className="px-2 py-4 ">S/N</th>
              <th className="px-2 py-4 ">Name</th>
              <th className="px-2 py-4">Email</th>
              <th className="px-2 py-4">Phone Number</th>
              <th className="px-2 py-4">Employment status</th>
              <th className="px-2 py-4">Address</th>
              <th className="px-2 py-4">Account Type</th>
              <th className="px-2 py-4">Verification stage</th>
              <th className="px-2 py-4">Created on</th>
              <th className="px-2 py-4">Force Delete User</th>
            </tr>
          </thead>
          <tbody className="px-4 text-nowrap">
            {isLoading ? (
              <Preloader />
            ) : filteredData.length > 0 ? (
              filteredData.map((data: any, index: number) => {
                return (
                  <tr
                    className="border-b border-gray-300 py-2 hover:bg-gray-50"
                    key={index}
                  >
                    <td className="text-secondary p-2">{index + 1}</td>
                    <td className="p-2">
                      {data.firstName && data.lastName
                        ? `${data.firstName} ${data.lastName}`
                        : "Not available"}
                    </td>
                    <td className="p-2">{data.email}</td>
                    <td className="p-2">
                      {data.phoneNumber ? data.phoneNumber : "Not available"}
                    </td>
                    <td className="p-2 lowercase">
                      {data.employmentStatus
                        ? data.employmentStatus
                        : "Not available"}
                    </td>
                    <td className="p-2 capitalize">
                      {data.address
                        ? data.address.toLowerCase()
                        : "Not available"}
                    </td>
                    <td className="p-2">{data.accountType}</td>
                    <td className="p-2">{data.isComplete}</td>
                    <td className="p-2">
                      {new Date(data.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                    <td className="p-2">
                      <button
                        type="button"
                        className={`${
                          isLoading.delete ? "bg-red-100" : "bg-red-600"
                        } p-2 text-sm text-white rounded-md`}
                        onClick={() => handleDeleteClick(data)}
                        disabled={isLoading.delete}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className="text-center text-secondary p-5">
                  No user available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default IncompleteAccounts;
