import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import { Preloader } from "../components/elements/Preloader";
import { API_URL } from "../App";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

const InstituteUpload = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);

  const [isLoading, setIsLoading] = useState({
    allPage: false,
    addInstitute: false,
    deleteBtn: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inputName, setInputName] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [allInstitutes, setAllInstitutes] = useState<any>([{ name: "" }]);

  useEffect(() => {
    getInstitutes();
  }, []);

  const handleAddInstitute = async () => {
    setIsLoading((prev) => ({
      ...prev,
      addInstitute: true,
    }));
    try {
      const payload = { name: inputName };
      await axios.post(
        `${API_URL}/institutes`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setInputName("");
      getInstitutes();
      setIsLoading((prev) => ({
        ...prev,
        addInstitute: false,
      }));
      toast.success("Institute added successfully");
    } catch (error) {
      console.error(error);
      setInputName("");
      setIsLoading((prev) => ({
        ...prev,
        addInstitute: false,
      }));
    }
  };

  const getInstitutes = async () => {
    setIsLoading((prev) => ({
      ...prev,
      allPage: true,
    }));
    try {
      const res = await axios.get(
        `${API_URL}/institutes`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAllInstitutes(res.data);
      setIsLoading((prev) => ({
        ...prev,
        allPage: false,
      }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({
        ...prev,
        allPage: false,
      }));
    }
  };

  const handleDelete = (id: string, name: string) => {
    setInstituteId(id);
    setInstituteName(name);
    setShowDeleteModal((prev) => !prev);
  };

  const deleteInstitute = async (instituteId: string) => {
    setIsLoading((prev) => ({
      ...prev,
      deleteBtn: true,
    }));
    try {
      const res = await axios.delete(
        `${API_URL}/institutes/${instituteId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsLoading((prev) => ({
        ...prev,
        deleteBtn: false,
      }));
      setShowDeleteModal(false);
      toast.success(res.data.message);
      getInstitutes();
    } catch (error: any) {
      console.error(error);
      setIsLoading((prev) => ({
        ...prev,
        deleteBtn: false,
      }));
      setShowDeleteModal(false);
      toast.error(error || "Failed to delete institute");
    }
  };

  return (
    <main>
      <div className="bg-white p-3 rounded-md mb-3">
        <h1 className="font-bold text-lg">Institute Upload</h1>
      </div>
      <section className="flex mt-5 justify-evenly flex-wrap gap-3">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading.allPage && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Students
          </h2>
          <p className="font-semibold text-3xl"> </p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading.allPage && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Schools
          </h2>
          <p className="font-semibold text-3xl">{allInstitutes.length}</p>
        </div>
      </section>

      <section className="bg-white p-3 my-3">
        <div className="mb-2">
          <label htmlFor="name">Name of Institute: </label>
          <input
            type="text"
            className="border p-2 rounded-md w-2/3 text-sm"
            name="name"
            id="name"
            placeholder="Enter the name of the institute you want to add."
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="bg-secondary text-white text-sm p-2 rounded-md w-32"
          onClick={handleAddInstitute}
        >
          {isLoading.addInstitute ? <Preloader /> : "Add Institute"}
        </button>
      </section>
      <section>
        <table className="w-full text-left">
          <thead className="bg-gray-50 h-12 rounded-md">
            <tr>
              <th className="px-2">SN</th>
              <th className="px-2">Name of Institute</th>
              <th className="px-2"></th>
            </tr>
          </thead>
          <tbody>
            {isLoading.allPage ? (
              <tr>
                <td colSpan={4} className="p-5">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : allInstitutes && allInstitutes.length > 0 ? (
              allInstitutes.map((item: any, index: any) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{index + 1}</td>
                  <td className="w-8/12">{item.name}</td>
                  <td className="py-2">
                    <button
                      type="button"
                      className="bg-red-500 text-white p-2 w-20 text-sm"
                      onClick={() => handleDelete(item.id, item.name)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-secondary text-center p-5">
                  No Institutes Found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div>
                <h3 className="text-xl my-3 font-semibold text-center">
                  <span className="font-normal">Delete</span> {instituteName}?
                </h3>
                <div className="flex justify-center gap-5">
                  <button
                    type="button"
                    className={`w-28 px-5 py-3 rounded text-white bg-[#008b50] ${
                      isLoading && "pb-[1.1rem]"
                    }`}
                    onClick={() => deleteInstitute(instituteId)}
                  >
                    {isLoading.deleteBtn ? <Preloader /> : "Yes"}
                  </button>
                  <button
                    type="button"
                    className="w-28 px-5 py-3 bg-red-500 rounded text-white "
                    onClick={() => setShowDeleteModal((prev: any) => !prev)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default InstituteUpload;
