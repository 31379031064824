import axios from "axios";
import { useState, useEffect } from "react";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import SelfEmployed from "../../components/modals/SelfEmployed";
import Employed from "../../components/modals/Employed";
import OutrightCustomers from "../../components/OutrightCustomers";
import Student from "../../components/modals/Student";
import { AllBanksModal } from "../../components/modals/AllBanksModal";
import { DeleteUser } from "../../components/modals/DeleteUser";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import IncompleteAccounts from "../../components/IncompleteAccounts";

const Users = () => {
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState("");
  const [selectedInfo, setSelectedInfo] = useState<any>({});
  const [customers, setCustomers] = useState([]);
  const [outright, setOutright] = useState([]);
  const [activeTab, setActiveTab] = useState("flexible");
  const [isLoading, setIsLoading] = useState<{
    main: boolean;
    delete: boolean;
  }>({
    main: false,
    delete: false,
  });
  const [isFlexibleLoading, setIsFlexibleLoading] = useState(false);
  const [selfEmployedModalOpen, setSelfEmployedModalOpen] = useState(false);
  const [allBanksModal, setAllBanksModal] = useState(false);
  const [allBanksDetails, setAllBanksDetails] = useState([]);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [incompleteUserLoading, setIncompleteUserLoading] = useState(false)
  const [incomplete, setIncomplete] = useState([])

  const getAllIncompleteUsers = async() => {
    setIncompleteUserLoading(true)
    try {
      const res = await axios.get(`${API_URL}/incompleteRegisteration`,
        {
          headers: {
            Authorization: token,
          }
        },
      )
      setIncomplete(res.data)
      setIncompleteUserLoading(false)
    } catch (error) {
      console.error(error)
      setIncompleteUserLoading(false)
    }
  }

  useEffect(() => {
    getFlexibleCustomers();
    getOutrightCustomers();
    getAllIncompleteUsers();
  }, []);

  const handleCloseModal = () => {
    setShowDeleteUser(false)
    getFlexibleCustomers();
    getOutrightCustomers();
    getAllIncompleteUsers();
  }

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleViewAllBank = (id: string, linkedBanks: any) => {
    setUserId(id);
    setAllBanksDetails(linkedBanks);
    setAllBanksModal((prev) => !prev);
  };

  const handleJobClick = (details: string, occupation: any) => {
    setSelectedInfo(details);
    if (occupation === "Self-Employed") {
      setSelfEmployedModalOpen(true);
    } else if (occupation === "Employed") {
      setEmployedModalOpen(true);
    } else {
      setStudentModalOpen(true);
    }
  };

  const getFlexibleCustomers = async () => {
    setIsLoading((prev) => ({ ...prev, main: true }));
    try {
      const res = await axios.get(
        `${API_URL}/getapprovedCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsLoading((prev) => ({ ...prev, main: false }));
      setCustomers(res.data);
    } catch (error) {
      console.log(error);
      setCustomers([]);
      setIsLoading((prev) => ({ ...prev, main: false }));
    }
  };

  const getOutrightCustomers = async () => {
    setIsFlexibleLoading(true);
    try {
      const res = await axios.get(
        `${API_URL}/getOutrightCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsFlexibleLoading(false);
      setOutright(res.data);
    } catch (error) {
      console.log(error);
      setIsFlexibleLoading(false);
      setOutright([]);
    }
  };

  const handleDeleteClick = (data: any) => {
    setShowDeleteUser((prev) => !prev);
    setUserToDelete(data);
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="flex gap-10 mb-6">
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "outright" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "incomplete" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("incomplete")}
        >
          Incomplete Accounts
        </span>
      </div>
      {activeTab === "flexible" ? (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div
            className={`w-full overflow-x-auto ${
              isLoading.main && "animate-pulse h-[50vh]"
            }`}
          >
            <div
              className="flex items-center justify-between p-6"
              style={{ minWidth: "700px" }}
            >
              <div className="flex justify-between w-full">
                <h1 className="text-base font-semibold ">User Details</h1>
                <label htmlFor="filter">
                  Sort:
                  <select
                    name="filter"
                    id="filter"
                    className="border p-2 ml-4"
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="employed">Employed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Students</option>
                  </select>
                </label>
              </div>
            </div>
            <section className="overflow-x-auto">
              <table className="w-[1200px]" style={{ minWidth: "700px" }}>
                <thead className="bg-gray-50 font-bold p-4 text-left">
                  {filter === "student" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 ">Name</th>
                      <th className=" p-2">Email</th>
                      <th className="p-2 ">BVN</th>
                      <th className="p-2">All Banks</th>
                      <th className="p-2">Education</th>
                      <th className="p-2">Credit Score</th>
                    </tr>
                  )}
                  {filter === "employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 ">Name</th>
                      <th className=" p-2">Email</th>
                      <th className="p-2 ">BVN</th>
                      <th className="p-2">All Banks</th>
                      <th className="p-2">Job</th>
                      <th className="p-2">Credit Score</th>
                      <th className="p-2">Force Delete User</th>
                    </tr>
                  )}
                  {filter === "all" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 ">Name</th>
                      <th className=" p-2">Email</th>
                      <th className="p-2 ">BVN</th>
                      <th className="p-2">All Banks</th>
                      <th className="p-2">Occupation</th>
                      <th className="p-2">Credit Score</th>
                      <th className="p-2">Force Delete User</th>
                    </tr>
                  )}
                  {filter === "self-employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 ">Name</th>
                      <th className=" p-2">Email</th>
                      <th className="p-2 ">BVN</th>
                      <th className="p-2">All Banks</th>
                      <th className="p-2">Business</th>
                      <th className="p-2">Credit Score</th>
                      <th className="p-2">Force Delete User</th>
                    </tr>
                  )}
                </thead>
                <tbody className="px-4">
                  {customers
                    .filter((customer: any) => {
                      if (filter === "employed") {
                        return customer.employmentStatus === "employed";
                      } else if (filter === "self-employed") {
                        return customer.employmentStatus === "selfEmployed";
                      } else if (filter === "student") {
                        return customer.employmentStatus === "student";
                      } else if (filter === "all") {
                        return customer.employmentStatus;
                      }
                      return true;
                    })
                    .map((data: any, index) => {
                      const filteredJob =
                        data.employmentStatus === "employed"
                          ? "Employed"
                          : data.employmentStatus === "selfEmployed"
                          ? "Self-Employed"
                          : data.employmentStatus === "student"
                          ? "Student"
                          : null;

                      const filteredJobObject =
                        data.employmentStatus === "employed"
                          ? data.employed
                          : data.employmentStatus === "selfEmployed"
                          ? data.selfEmployed
                          : data.employmentStatus === "student"
                          ? data.student
                          : null;
                      return (
                        <tr
                          className="border-b border-gray-300 py-2"
                          key={index}
                        >
                          <td className="text-secondary p-2">{index + 1}</td>
                          <td className="p-2">
                            {data.lastName} {data.firstName}
                          </td>
                          <td className="p-2">{data.email}</td>
                          <td className="p-2">
                            <button
                              onClick={() => handleBvnClick(data)}
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {data.bvn}
                            </button>
                          </td>
                          <td className="p-2">
                            <button
                              className="bg-secondary text-white text-sm rounded-md p-2"
                              onClick={() =>
                                handleViewAllBank(data._id, data.linkedBanks)
                              }
                            >
                              View
                            </button>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={() =>
                                handleJobClick(filteredJobObject, filteredJob)
                              }
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {filteredJob}
                            </button>
                          </td>
                          <td className="p-2">
                            ₦{data.creditScore.toLocaleString()}
                          </td>
                          <td className="p-2">
                            <button
                              type="button"
                              className={`${
                                isLoading.delete ? "bg-red-100" : "bg-red-600"
                              } p-2 text-sm text-white rounded-md`}
                              onClick={() => handleDeleteClick(data)}
                              disabled={isLoading.delete}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </section>
          </div>
        </div>
      ) : activeTab === "outright" ? (
        <OutrightCustomers
          outright={outright}
          isLoading={isFlexibleLoading}
          handleDeleteClick={handleDeleteClick}
        />
      ) : (
        <IncompleteAccounts 
          incomplete={incomplete}
          isLoading={incompleteUserLoading}
          handleDeleteClick={handleDeleteClick}
        />
      )}

      {showDeleteUser && (
        <DeleteUser
          userToDelete={userToDelete}
          handleCloseModal={handleCloseModal}
        />
      )}

      {allBanksModal && (
        <AllBanksModal
          setAllBanksModal={setAllBanksModal}
          setAllBanksDetails={setAllBanksDetails}
          allBanksDetails={allBanksDetails}
          flexibleCustomersFunc={getFlexibleCustomers}
          userId={userId}
        />
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}

      {selfEmployedModalOpen && (
        <SelfEmployed
          setSelfEmployedModalOpen={setSelfEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {employedModalOpen && (
        <Employed
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {studentModalOpen && (
        <Student
          selectedInfo={selectedInfo}
          setStudentModalOpen={setStudentModalOpen}
        />
      )}
    </main>
  );
};

export default Users;
