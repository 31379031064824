import axios from "axios";
import { useEffect, useState } from "react";
import { EMandateAccts } from "../components/modals/EmandateAccts";
import { API_URL } from "../App";
import { RootState } from "../redux/store";
import { useAppSelector } from "../redux/hooks";

export const Emandate = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [allEmandate, setAllEmandate] = useState<[]>([]);
  const [showEmandateModal, setShowEmandateModal] = useState(false);
  const [acctNameClick, setAcctNameClick] = useState({
    acctName: "",
    accounts: [],
  });

  useEffect(() => {
    const fetchMandates = async () => {
      try {
        const res = await axios.get(
          `${API_URL}/getActiveMandates
          `,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(res.data);
        setAllEmandate(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMandates();
  }, []);

  const filteredEmandate = allEmandate.filter(
    (account: any, index: number, self: any) =>
      index ===
      self.findIndex((t: any) => t.account_name === account.account_name)
  );

  const handleViewMore = (acctName: string) => {
    setAcctNameClick((prev) => ({
      ...prev,
      acctName: acctName,
    }));

    const acctArray = allEmandate.filter(
      (acct: any) => acct.account_name === acctName
    );
    console.log("acctArray", acctArray);
    
    setAcctNameClick((prev) => ({
      ...prev,
      accounts: acctArray,
    }));

    setShowEmandateModal((prev) => !prev);
  };

  return (
    <main>
      <div className="bg-white p-3 mt-3 rounded-md">
        <h1 className="font-bold text-lg">E-Mandate List</h1>
      </div>
      <section>
        <table className="w-full my-3">
          <thead>
            <tr className="bg-gray-50 font-bold md:text-base text-sm h-12 text-left">
              <th className="pl-3">SN</th>
              <th className="pl-3">Client name</th>
              <th className="pl-3"></th>
            </tr>
          </thead>
          <tbody className="md:text-base text-sm">
            {filteredEmandate.length > 0 ? (
              filteredEmandate.map((item: any, index: number) => (
                <tr key={index} className="border-b-2">
                  <td className="p-3">{index + 1}</td>
                  <td className="p-3">{item.account_name}</td>
                  <td className="p-3">
                    <button
                      type="button"
                      className="bg-secondary text-white p-2 rounded-md text-sm"
                      onClick={() => handleViewMore(item.account_name)}
                    >
                      View More
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center text-secondary p-5">
                  No Mandates found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {showEmandateModal && (
        <EMandateAccts
          setShowEmandateModal={setShowEmandateModal}
          acctNameClick={acctNameClick}
        />
      )}
    </main>
  );
};
